<template lang="pug">
teleport(to='body' v-if="true")
  .modal(v-if="isModalVisible")
    .modal__overlay(@click="onClose")
    ui-popup.modal__container(
      v-bind="$props" 
      v-model="showModal" 
      @close="onClose" 
      ref="popup"
      @mainButtonClick="onMainButtonClick"
      @additionalButtonClick="onAdditionalButtonClick"
    )
      slot
</template>

<script setup lang="ts">
import UiPopup from '@/components/ui/Popup/index.vue';
interface Value {
  value: boolean;
}

interface Props {
  title: string
  titleTextCenter?: boolean
  size?: 'lg' | 'md'
  mainButtonLabel?: string
  additionalButtonLabel?: string
  mainButtonColor?: 'default' | 'warning' | 'primary' | 'danger'
  additionalButtonColor?: 'default' | 'warning' | 'primary' | 'danger'
  mainButtonSize?: 'lg' | 'md' | 'sm'
  additionalButtonSize?: 'lg' | 'md' | 'sm'
  showAdditionalButton?: boolean
  showTwoAdditionalButton?: boolean
  showButtons?: boolean
  modelValue?: boolean
  fullWidthMainButton?: boolean
  isLeftTitle?: boolean
  withBackBtn?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'lg',
  mainButtonLabel: 'Text',
  additionalButtonLabel: 'Text',
  mainButtonColor: 'default',
  additionalButtonColor: 'default',
  mainButtonSize: 'sm',
  additionalButtonSize: 'sm',
  showButtons: true,
  withBackBtn: false
})

const emit = defineEmits<{
  'update:modelValue': [value: Value]
  'close': void,
  'mainButtonClick',
  'additionalButtonClick'
}>()

const showModal = ref<boolean>(false)
const mainStore = useMainStore()

const isModalVisible = computed<boolean>(() => {
  return showModal.value
})

watch(() => props.modelValue, (value) => {
  showModal.value = value
})

watch(showModal, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    mainStore.toggleModalOverlay()
  }
})

const popup = ref<InstanceType<typeof UiPopup>>()

const openModal = () => {
  showModal.value = true;
  popup.value?.openModal()
}

const onClose = (event: Event) => {
  showModal.value = false;
  emit('update:modelValue', false)
  emit('close', event)
}

const onMainButtonClick = (event: Event) => {
  emit('mainButtonClick', event)
}

const onAdditionalButtonClick = (event: Event) => {
  emit('additionalButtonClick', event)
}

const onEscKey = (event: KeyboardEvent) => {
  if (event.keyCode === 27 && showModal.value) {
    onClose()
  }
}

onMounted(() => {
  window.addEventListener('keyup', onEscKey)
})

onBeforeUnmount(() => {
  window.removeEventListener('keyup', onEscKey)
  mainStore.closeModalOverlay()
}) 

defineExpose({ openModal, onClose });
</script>

<style lang="scss" scoped>
.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &__container {
    margin-left: auto;
    margin-right: auto;
    position: static;
  }
}

.modal__overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

@include mobile {
  .modal {
    &__container {
      margin-left: auto;
      margin-right: auto;
      position: inherit;
    }
  }

  .modal__overlay {
    padding: get-vw(53, "sm") 0 get-vw(53, "sm") 0;
    height: calc(100dvh - (get-vw(53, "sm") + get-vw(53, "sm")));
  }
}
</style>
