<template lang="pug">
.popup-container(v-if="isPopupVisible")
  .popup-overlay
  .popup-box(:class="[size, titleTextCenter ? 'title-text-center' : '']")
    .popup-header
      .custom-header-title(v-if="customHeaderTitle") {{ title }}
      .title(v-else :class="[isLeftTitle ? 'is-left': '']") 
        .back-btn(v-if="withBackBtn" @click="emit('backBtnClick')") 
          Arrow.icon
        span {{ title }}
        button.button-close(@click="onClose" :class="{ 'hidden': hideCloseButton }")
          cross-icon.close-icon
    .combined-body-footer(v-if="combineBodyAndFooter")
      .popup-body(:class="{ 'custom-body': customBodyClass }")
        slot
      .popup-footer(v-if="showButtons")
        ui-button.button(
          :class="[fullWidthMainButton ? 'full-width' : '']"
          :title="mainButtonLabel"
          :color="mainButtonColor"
          :size="mainButtonSize"
          :fullWidth="!disableFullWidthFirstButton"
          @click="emit('mainButtonClick')"
        )
        ui-button.button(
          v-if='showAdditionalButton'
          :title="additionalButtonLabel"
          :color="additionalButtonColor"
          :size="additionalButtonSize"
          fullWidth
          @click="emit('additionalButtonClick')"
        )
        ui-button.button(
          v-if='showTwoAdditionalButton'
          :title="additionalTwoButtonLabel"
          :color="additionalTwoButtonColor"
          :size="additionalTwoButtonSize"
          fullWidth
          @click="emit('additionalTwoButtonClick')"
        )
    .popup-body(v-else :class="{ 'custom-body': customBodyClass }")
      slot
    .popup-footer(v-if="!combineBodyAndFooter && showButtons")
      ui-button.button(
        :class="[fullWidthMainButton ? 'full-width' : '']"
        :title="mainButtonLabel"
        :color="mainButtonColor"
        :size="mainButtonSize"
        :fullWidth="!disableFullWidthFirstButton"
        @click="emit('mainButtonClick')"
      )
      ui-button.button(
        v-if='showAdditionalButton'
        :title="additionalButtonLabel"
        :color="additionalButtonColor"
        :size="additionalButtonSize"
        fullWidth
        @click="emit('additionalButtonClick')"
      )
      ui-button.button(
        v-if='showTwoAdditionalButton'
        :title="additionalTwoButtonLabel"
        :color="additionalTwoButtonColor"
        :size="additionalTwoButtonSize"
        fullWidth
        @click="emit('additionalTwoButtonClick')"
      )
</template>

<script setup lang="ts">
import CrossIcon from '@/assets/svg/close-modal.svg'
import Arrow from '@/assets/svg/arrow-down.svg'

interface Props {
  title: string
  titleTextCenter?: boolean
  size?: 'lg' | 'md' | 'sm'
  mainButtonLabel?: string
  additionalButtonLabel?: string
  additionalTwoButtonLabel?: string
  mainButtonColor?: 'default' | 'warning'
  additionalButtonColor?: 'default' | 'warning'
  additionalTwoButtonColor?: 'default' | 'warning'
  mainButtonSize?: 'lg' | 'md' | 'sm' | 'xxs'
  additionalButtonSize?: 'lg' | 'md' | 'sm'
  additionalTwoButtonSize?: 'lg' | 'md' | 'sm'
  showAdditionalButton?: boolean
  showTwoAdditionalButton?: boolean
  showButtons?: boolean
  fullWidthMainButton?: boolean
  isLeftTitle?: boolean
  modelValue?: boolean
  disableFullWidthFirstButton?: boolean
  hideCloseButton?: boolean
  combineBodyAndFooter?: boolean
  customHeaderTitle?: boolean
  customBodyClass?: boolean
  customMainButtonClass?: boolean
  withBackBtn?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'lg',
  mainButtonLabel: 'Text',
  additionalButtonLabel: 'Text',
  additionalTwoButtonLabel: 'Text',
  mainButtonColor: 'default',
  additionalButtonColor: 'default',
  additionalTwoButtonColor: 'default',
  mainButtonSize: 'xxs',
  additionalButtonSize: 'sm',
  additionalTwoButtonSize: 'sm',
  showButtons: true,
  disableFullWidthFirstButton: false,
  hideCloseButton: false,
  combineBodyAndFooter: false,
  customHeaderTitle: false,
  customBodyClass: false,
  customMainButtonClass: false,
  withBackBtn: false
})

const emit = defineEmits([
  'mainButtonClick',
  'additionalButtonClick',
  'additionalTwoButtonClick',
  'close',
  'update:modelValue',
  'backBtnClick'
])

const showPopup = ref<boolean>(false)

const isPopupVisible = computed<boolean>(() => {
  return showPopup.value || props.modelValue
})

const openModal = () => {
  showPopup.value = true;
}

const onClose = (event: Event) => {
  showPopup.value = false;
  emit('update:modelValue', false)
  emit('close', event)
}

defineExpose({ openModal });

</script>

<style lang="scss" scoped>
.popup-container {
  position: fixed;
  left: get-vw(115);
  display: flex;
  justify-content: left;
  z-index: 12;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.popup-header {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background: $white;
  color: $white;
  margin-bottom: get-vw(0);
}

.popup-header .title {
  @include headline-h3-h3;
  margin: 0;
  color: $black;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  position: relative;
  height: get-vw(56);
  width: get-vw(56);
  margin-bottom: get-vw(36);
  width: 100%;

  &.is-left {
    margin-right: auto;
  }
}

.popup-header .custom-header-title  {
  @include комент-автор;
  color: $black;
  margin: 0 auto get-vw(18) 0;
}

.button-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: none;
  background-color: transparent;
  height: get-vw(56);
  width: get-vw(56);
  background-size: cover;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0;
}

.hidden {
  visibility: hidden;
}

.close-icon {
  width: 100%;
  height: 100%; 
}

.popup-box {
  padding: get-vw(29) get-vw(36) get-vw(36);
  position: relative;
  background: $white;
  overflow: hidden;
  border-radius: get-vw(26);
  box-sizing: border-box;
  @include bars;

  &.lg {
    width: get-vw(1128);
  }

  &.md {
    border-radius: get-vw(26);
    padding: get-vw(36) get-vw(72) get-vw(72);
    width: get-vw(616);

    .title {
      @include headline-h4;
    }

    .button-close {
      right: get-vw(-28);
    }

    .back-btn {
      left: get-vw(-28);
    }
  }

  &.sm {
    padding: get-vw(18);
    width: get-vw(592);
  }

  &.title-text-center {
    .popup-header {
      align-items: center;
      flex-direction: column;
    }

    .popup-header .title {
      text-align: center;
      width: 100%;
    }
  }
}

.combined-body-footer {
  display: flex;
  align-items: center;
  gap: get-vw(24)
}

.popup-body {
  @include text-t4-regular;
  max-height: calc(100vh - 120px);
  overflow: auto;
}

.popup-body.custom-body {
  @include комент-текст;
}

.popup-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  gap: get-vw(36)
}

a {
  color: $links;
}

@include tablet {
  .popup-header .title {
    @include headline-h3-m;
    height: get-vw(40, 'md');
    margin-bottom: get-vw(24, 'md');
  }
  .popup-box {
    padding: get-vw(16, "md") get-vw(24, "md") get-vw(24, "md");
    border-radius: get-vw(18, "md");

    &.lg {
      padding: get-vw(16, "md") get-vw(24, "md") get-vw(24, "md");
      width: get-vw(675, "md");
    }

    &.md {
      border-radius: get-vw(18, 'md');
      padding: get-vw(11, 'md') get-vw(16, 'md') get-vw(16, 'md');
      width: get-vw(298, 'md');

      .title {
        @include text-t8-medium-m;
        height: get-vw(30, 'md');
        width: get-vw(30, 'md');
        margin-bottom: get-vw(24, 'md');
      }

      .button-close {
        height: get-vw(30, 'md');
        width: get-vw(30, 'md');
        right: get-vw(-6, 'md');
      }

      .back-btn {
        height: get-vw(30, 'md');
        width: get-vw(30, 'md');
        left: get-vw(-6, 'md');
      }
    }

    &.sm {
      border-radius: get-vw(16, 'md');
      padding: get-vw(12, 'md');
      width: get-vw(437, 'md');
    }
  }

  .button-close {
    height: get-vw(40, 'md');
    width: get-vw(40, 'md');
  }

  .combined-body-footer {
    gap: get-vw(24, "md")
  }

  .popup-header .custom-header-title  {
    @include text-t4-semibold-m;
    margin-bottom: get-vw(18, 'md')
  }

  .popup-body.custom-body {
    @include text-t4-regular-m;
  }
}

@include mobile {
  .popup-container {
    left: 0;
    bottom: get-vw(40, "sm");
  }

  .popup-footer {
    flex-direction: column;
    gap: get-vw(12, sm);
    background-color: #f9f9f9;
  }

  .popup-box {
    padding: get-vw(15, sm) get-vw(15, sm) get-vw(30, sm) get-vw(15, sm);
    border-radius: get-vw(8, "sm");
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: get-vw(8, "sm");
    border-top-right-radius: get-vw(8, "sm");
    box-shadow: none;

    &.lg, &.md {
      border-radius: get-vw(8, "sm");
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-sizing: border-box;
      padding: get-vw(15, sm) get-vw(15, sm) get-vw(30, sm) get-vw(15, sm);
      width: 100vw;

      .title {
        @include text-t8-medium-m("sm");
        height: auto;
        margin-bottom: 0;
      }
    }

    &.md {
      padding: get-vw(16, 'sm') get-vw(54, 'sm') get-vw(54, 'sm');
      @include new-shadow;

      .back-btn {
        height: 100%;

        &:deep(svg) {
          width: get-vw(30, 'sm');
        }
      }
    }

    &.sm {
      border-radius: 0;
      border-top-left-radius: get-vw(8, "sm");
      border-top-right-radius: get-vw(8, "sm");
      padding: get-vw(9, "sm") get-vw(11, "sm") get-vw(10, "sm") get-vw(8, "sm");
      width: 100vw;

      .popup-header {
        margin-bottom: 0;
      }
    }
  }

  .popup-header {
    margin-top: 0;
    margin-bottom: get-vw(24, "sm");
    background-color: transparent;
  }

  .popup-header .custom-header-title  {
    @include text-t8-semibold-s;
    margin-bottom: get-vw(3, 'sm');
  }

  .popup-body.custom-body {
    @include text-t8-regular-s;
    overflow: hidden;
    max-width: get-vw(190, "sm");
    line-height: 3vw;
  }

  .combined-body-footer {
    justify-content: space-between
  }

  .custom-main-button {
    @include text-t4-semobold-s;
    padding: get-vw(7, "sm") get-vw(10, "sm");
  }

  .popup-header .title {
    @include text-t8-medium-m("sm");
    height: auto;
    margin-bottom: 0;
  }

  .button-close {
    display: none;
  }

  .popup-body {
    // @include text-t4-regular;
    font-size: get-vw(16, "sm");
    font-weight: 400;
    line-height: get-vw(18.78, "sm");
    overflow: auto;
  }
}

.back-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  height: get-vw(56);
  width: get-vw(56);

  top: 0;
  left: 0;

  .icon {
    transform: rotate(90deg);
    width: get-vw(28);
  }
}
</style>
